<template>
  <div>
    <VTitle :title="$t('app.overview')" class="section-title" />

    <div v-if="isLoading" class="flex justify-center">
      <LoadingIcon icon="three-dots" class="w-8 h-8" />
    </div>

    <div v-else class="grid grid-cols-3 gap-8">
      <div class="col-span-3 lg:col-span-2">
        <div class="grid grid-cols-2 gap-8 mb-8">
          <div>
            <VStatisticsCard
              :title="$t('app.results', 2)"
              :statistics="data.results"
            />
          </div>

          <div>
            <VStatisticsCard
              :title="$t('app.status')"
              :statistics="data.registration_statuses"
            />
          </div>
        </div>

        <PendingApprovals
          :completion-requests="data.completion_requests"
          :registration-requests="data.registration_requests"
        />
      </div>

      <div class="col-span-3 lg:col-span-1">
        <div class="mb-8">
          <VRatings
            :rating-count="data.collection?.rating_count ?? 0"
            :rating="data.collection?.rating ?? 0"
          />
        </div>

        <div class="mb-8">
          <Statistics :statistics="data.statistics" />
        </div>

        <div class="mb-8">
          <Versions :route="route" :versions="data.versions" />
        </div>

        <div>
          <LatestFeedback :feedback="data.latest_feedback" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { onMounted, ref, computed } from "vue";
import { useI18n } from "vue-i18n";
// Composables
import useTexts from "@/composables/useTexts";
import useCollection from "@/composables/useCollection";
import useRequest from "@/composables/useRequest";
// Components
import VTitle from "@/components/VTitle";
import Versions from "@/components/templates/Versions";
import Statistics from "@/views/teaching/lessons/overview/Statistics";
import VStatisticsCard from "@/components/VStatisticsCard";
import VRatings from "@/components/VRatings";
import LatestFeedback from "@/components/templates/LatestFeedback";
import PendingApprovals from "@/components/templates/PendingApprovals";

export default {
  components: {
    VTitle,
    Versions,
    Statistics,
    VStatisticsCard,
    VRatings,
    PendingApprovals,
    LatestFeedback
  },
  props: {
    id: {
      type: [Number, String],
      required: true
    },
    resource: {
      type: Object,
      default: () => ({})
    }
  },
  setup(props) {
    // Misc
    const { t } = useI18n();

    // Composables
    const { isRequesting: isLoading, request } = useRequest();
    const { endpoint, route } = useCollection();
    const { getText } = useTexts();

    // Data
    const data = ref({});

    // Computed
    const documentTitle = computed(() => {
      // eslint-disable-next-line
      const title = `${t("app.overview")} - ${t("app.collections", 2)} - ${t("app.teaching")}`;

      const name = getText(props.resource?.texts, "name");
      if (name) {
        return `${name} - ${title}`;
      }

      return title;
    });

    // Methods
    const getOverview = async () => {
      const response = await request({
        endpoint: `${endpoint}.overview`,
        pathParams: {
          id: props.id
        },
        showToaster: false
      });

      data.value = response?.payload?.data;
    };

    // Lifecycle Hooks
    onMounted(() => {
      getOverview();
    });

    return {
      documentTitle,
      data,
      // useRequest
      isLoading,
      // useCollection
      route
    };
  }
};
</script>
